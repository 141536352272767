<template>

    <StaffList
        :heading="heading"
        :loading="$store.getters.loading"
        :staffList="list"></StaffList>

</template>

<script>
    import {getBuildingStaff} from '../lib/api'
    import {getPrivateBuildingStaff, getPremiseBuildingStaff} from '../lib/private-api'
    import {debugMsg} from '../lib/helpers'

    import StaffList from '../components/layouts/StaffList'
	export default {
		name: "buildings-page",
		components: {
            StaffList
		},

		data: () => ({
            campus: "",
            building: "",
            heading: "",
            list: []
		}),

		methods: {
            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);

                // Check if API returned any results
                if (res.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex

                    this.$store.commit("updateStaff", res);

                    this.list = this.$store.getters.staff;

                    if (this.list.length == 0) {
                        this.$store.commit("errorMsg", `No results returned`);
                    } else {

                        if (this.list[0]["Building Name"] == undefined) {
                            // single building
                            this.heading = `Building ${this.building} Staff Directory`

                            this.list = this.list.sort((a, b) => {
                                var aName = `${a["First Names"]} ${a["Surname"]}`;
                                var bName = `${b["First Names"]} ${b["Surname"]}`;

                                if (aName < bName) { return -1 }
                                if (aName < bName) { return 1 }
                                return 0;
                            })

                        } else {
                            // multiple buildings
                            var buildings = this.building.split(",")
                            var last = buildings[buildings.length -1]
                            var first = buildings.filter((b) => { return b != last })

                            this.heading = `Building ${first.join(', ')} & ${last} Staff Directory`

                            this.list = this.list.map((staff) => {

                                return {
                                    groupName: (staff["Building Name"] != null) ? `Building ${staff["Building Name"]}` : "",
                                    staffList: Array.from(staff["Staff"])
                                };
                            });
                        }
                    }
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                this.$store.commit('setLoading', false)
                // display error message
                debugMsg("Error requesting staff from campus buildings: ", err)
                this.$store.commit("errorMsg", `There was an error retrieving results`);

                this.errorLogin(err);
            },

            /**
            * Request a list of availible staff based on a specified campus & 
            * building from the Staff directory API
            */
            requestBuildingStaff: function() {
                this.$store.commit('setLoading', true)


                // get valid token then request details

                if (this.$route.path.match(/secure/gi) == null) {
                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateBuildingStaff(accessToken, this.campus, this.building)
                                .then((res) => {
                                    this.successSearch(res);
                                })
                                .catch((err) => {
                                    
                                    this.errorSearch(err);
                                })                        
                        },
                        (error) => {
                            this.$store.commit("errorMsg", `There was an error retrieving results`);

                        });
                } else {

                    getPremiseBuildingStaff(this.campus, this.building)
                        .then((res) => {
                            this.successSearch(res);
                        })
                        .catch((err) => {
                            
                            this.errorSearch(err);
                        })
                }
            },
        },


        computed: {
        },

        created: function() {
            this.campus = this.$route.params["campus"];
            this.building = this.$route.params["building"];
            this.requestBuildingStaff();
        }
	}
</script>