<template>

    <StaffList
        :heading="`Interpreter Staff Directory`"
        :staffList="list"
        :loading="$store.getters.loading"
        @selected="chosen"></StaffList>

</template>

<script>
	import {getInterpreters, getDialects} from '../lib/api'
    import {getPrivateInterpreters} from '../lib/private-api'
    import {debugMsg} from '../lib/helpers';

    import StaffList from '../components/layouts/StaffList'
	export default {
		name: "interpreter-page",
		components: {
            StaffList
		},

		data: () => ({
            list: [],
            selected: {},
		}),

        watch: {
            selected: function(newVal, oldVal) {
                if (newVal != oldVal) {

                    this.$store.commit("updateTemp", this.$store.getters.staff);
                    this.$store.commit("updateBack", true);
                    this.$router.push({path: `/interpreters/${newVal["DID"]}` })
                }
            },

            '$route': function(dialect) {
                if (this.$route.params.dialect) {
                    this.requestInterpreterStaff(this.$route.params.dialect);
                } else {

                    this.requestDialects();
                }
            }
        },

		methods: {

            chosen: function(choice) {
                this.selected = choice;
            },

            /**
            * Request a list of availible staff based on a specified campus & 
            * building from the Staff directory API
            */
            requestDialects: function() {
                this.$store.commit('setLoading', true);
                getDialects()
                    .then((res) => {
                        this.$store.commit('setLoading', false);

                        // store result in vuex
                        this.$store.commit("updateStaff", res);

                        this.list = this.$store.getters.staff.map((staff) => {

                            return {
                                DID: staff["DID"],
                                Position: staff["Dialect"]
                            }
                        }).filter((d) => { return d.Position != null});


                        this.$store.commit("updateStaff", {data: {data: this.list} });
                    })
                    .catch((err) => {
                        this.errorSearch(err);
                    })
            },


            /**
            * Request a list of availible staff based on a specified campus & 
            * building from the Staff directory API
            */
            requestInterpreterStaff: function(id) {
                this.$store.commit('setLoading', true)

                if (this.$store.getters.isLoggedIn) {
                    // get valid token then request details
                    this.$authService.getToken()                
                        .then( (accessToken) => {

                            getPrivateInterpreters(accessToken, id)
                                .then((res) => {
                                    this.successSearch(res);
                                })
                                .catch((err) => {
                                    this.errorSearch(err);
                                })
                        },
                        (error) => {
                            this.$store.commit("errorMsg", `There was an error retrieving results`);
                        });
                } else {
                    getInterpreters(id)
                    .then((res) => {
                        this.successSearch(res);
                    })
                    .catch((err) => {
                        this.errorSearch(err);
                    })
                }
            },

            /**
            * Handle a successful search response from the API
            */
            successSearch: function(res) {
                this.$store.commit('setLoading', false);
                // Check if API returned any results
                if (res.data.length == 0) {
                    this.$store.commit("errorMsg", "No results were found");
                } else {
                    // store result in vuex
                    this.$store.commit("updateStaff", res);

                    this.list = this.$store.getters.staff
                }
            },

            /**
            * Handle a successful search response from the API
            */
            errorSearch: function(err) {
                this.$store.commit('setLoading', false);
                this.$store.commit("errorMsg", `There was an error retrieving results`);
                this.errorLogin(err);
            },
		},


		computed: {
            
		},

        created: function() {

            if (this.$route.params.dialect) {
                this.requestInterpreterStaff(this.$route.params.dialect);
            } else {

                this.requestDialects();
            }
        }
	}
</script>